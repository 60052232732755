.tournaments-btns {
    margin-bottom: 2rem;
    font-size: 20px;
    justify-content: center;
    display: block;
    /* flex-wrap: wrap; */
  }
  
.tournaments-btns .btn {
    margin: 6px;
  }

/* .tournaments-info-wrapper {
    max-width: 800px;
    width: calc(100% - 40px); 
  }  */

/* .tournaments-info {
    font-size: 16px;
    margin-top: 8px;
  }  */

  @media screen and (max-width: 960px) {
    .tournaments-btns {
      width: 314px;
    }
  }