.info-footer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  font-size: 16px;
  text-align: center;
  max-width: 1200px;
  width: calc(100% - 40px); /* Ensure it takes full width minus the margin */
  color: #fff;
} 

.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }

  .footer-logo {
    height: 40px;
    width: auto; /* Maintain aspect ratio */
  }

  .footer-logo img {
    width: auto;  /* Maintain the original aspect ratio */
    height: 80px;  /* Set a fixed height */
    max-width: 100%;  /* Ensure it does not exceed its container width */
}
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: center; /* Center the logos horizontally */
    align-items: center; /* Align vertically */
    margin-bottom: 4rem;
    gap: 2rem; /* Creates space between the logos */
}
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 1200px) {
    .social-media-wrap {
      gap: 0.3rem; /* Smaller gap for smaller screens */
      flex-wrap: wrap; /* Enable wrapping of items */
      row-gap: 2rem; /* Adjust gap for better spacing when wrapped */
    }
  }
  
  @media screen and (max-width: 960px) {
    .footer-logo img {
      height: 64px; /* Smaller logos on mobile */
  }
    
    .footer-links {
      padding-top: 2rem;
    }

    .footer-input {
      width: 100%;
    }

    .btn {
      width: 100%;
    }

    .social-media-wrap {
      flex-wrap: wrap; /* Enable wrapping of items */
      row-gap: 2rem; /* Adjust gap for better spacing when wrapped */
      justify-content: center; /* Keep items centered when they wrap */
    }
}
  
 