.map-container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto; /* Center the container */
  height: 450px; /* Adjust height as needed */
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

