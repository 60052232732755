.carousel-container {
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden; /* Ensure the container does not overflow */
}

#tranding {
  background: #fff;
}

#tranding .tranding-slider {
  height: 45rem;
  position: relative;
}

@media (max-width: 1200px) {
  #tranding .tranding-slider {
    height: 38rem;
  }
}

@media (max-width: 1024px) {
  #tranding .tranding-slider {
    height: 34rem;
  }
}

@media (max-width: 600px) {
  #tranding .tranding-slider {
    height: 18rem; /* Allow height to be flexible */

  }
}

.tranding-slide {
  width: auto;
  height: 45rem;
  position: relative;
}

@media (max-width: 1200px) {
  .tranding-slide {
    height: 38rem;
  }
}

@media (max-width: 1024px) {
  .tranding-slide {
    height: 34rem;
  }
}

@media (max-width: 600px) {
  .tranding-slide {
    height: 18rem;
  }
}

.tranding-slide .tranding-slide-img img {
  width: auto;
  height: 45rem;
  border-radius: 0rem;
  object-fit: cover;
  margin-left: 4px;
  margin-right: 4px;
}

@media (max-width: 1200px) {
  .tranding-slide .tranding-slide-img img {
    height: 38rem;
  }
}

@media (max-width: 1024px) {
  .tranding-slide .tranding-slide-img img {
    height: 34rem;
  }
}

@media (max-width: 600px) {
  .tranding-slide .tranding-slide-img img {
    height: 18rem;
  }
}

.tranding-slider-control {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}