* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

h5 {
  font-weight: 400; /* Normal */
}

h4 {
  font-weight: 700; /* Bold */
}

.home,
.venue,
.info,
.transport,
.register,
.junior,
.u17,
.u15,
.u13 {
  display: flex;
  align-items: top;
  justify-content: center;
}

.venue {
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.transport {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.junior {
  background-color: #0D2526;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.u17 {
  background-color: #267073;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.u15 {
  background-color: #1A4A4C;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.u13 {
  background-color: #31969B;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}