video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  min-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
}

.hero-container > p {
  margin-top: 8px;
  margin-bottom: 32px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-container_tournament {
  min-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container_tournament > h1 {
  color: #fff;
  font-size: 100px;
}

.hero-container_tournament > p {
  margin-top: 8px;
  margin-bottom: 32px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.table-wrapper
{
  position: relative;
    overflow-x: auto; /* Allow horizontal scrolling */
    overflow-y: visible; /* Allow vertical scrolling */
    width: 100%;
    max-width: 1120px; /* Set maximum width */
    margin: auto; /* Center the table */
    display: block; /* Ensure block display */
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.label.time {
  background-color: #04aa6d;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  float: right; /* Align label to the right */
  margin-left: 8px; /* Add some space between label and text */
}

.label.discipline {
  background-color: #242424;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  float: right; /* Align label to the right */
  margin-left: 8px; /* Add some space between label and text */
}

.label.size {
  background-color: #31969b;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  float: left; /* Align label to the right */
  margin-left: 8px; /* Add some space between label and text */
}

.label.deadline {
  background-color: #ff0000;
  color: white;
  padding: 4px 16px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  float: left; /* Align label to the right */
  margin-left: 8px; /* Add some space between label and text */
  margin-bottom: 8px;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;    
  font-size: 18px; /* Adjusting font size */
}

.table th {
  background-color: #242424;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ddd; /* Separator stroke */
}

.table tbody tr {
  border-bottom: 1px solid #ddd; /* Separator stroke */
}

/* Add separator stroke between table columns */
.table th, .table td {
  border-right: 1px solid #ddd; /* Column separator stroke */
}

/* Remove right border for the last column to avoid extra separator */
.table th:last-child, .table td:last-child {
  border-right: none;
}

/* Remove right border for the last column to avoid extra separator */
.table tbody tr:last-child {
  border-bottom: none;
}

/* @media screen and (max-width: 1024x) {
  .hero-container_tournament {
    height: 90vh; 
  }
} */

@media only screen and (max-width: 1024px) {
  .table {
    padding: 0 0.5rem;
  }

  .table-wrapper{
    width: 100%;
  }
 
}

@media screen and (max-width: 768px) {

  .hero-container > h1 {
      font-size: 50px;
      /* margin-top: -100px; */
  }

  .hero-container > p {
      font-size: 30px;
  }

  .hero-container_tournament > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
  }

  .hero-container_tournament > p {
    font-size: 30px;
  }

  .table th,
  .table td {
      padding: 6px; /* Adjust padding for smaller screens */
      font-size: 14px; /* Decrease font size */
      text-align: center; /* Center-align text for smaller screens */
  }

  .table-wrapper {
    /* Existing styles */
    height: auto; /* Ensure it takes full height */
    width: 100%;
    overflow-y: auto; /* Allow scrolling */
    overflow-x: auto; /* Allow scrolling */
  }

  .label.time,
  .label.discipline,
  .label.size {
      float: none; /* Remove float for labels */
      margin-left: 0; /* Reset margin for labels */
      display: block; /* Make labels take up full width */
      text-align: center; /* Center-align labels for smaller screens */
  }

  .label.discipline,
  .label.size {
      margin-bottom: 1px; /* Add margin between rows for discipline label */
  }

  .label.time {
    margin-bottom: 2px; /* Add margin between rows for discipline label */
  }

  .table th,
  .table td,
  .table th:last-child,
  .table td:last-child {
      border-right: none; /* Remove right border on cells for cleaner appearance */
  }    
  .tournaments-btns {
    margin-bottom: 30px; /* Add margin-bottom to ensure space between the buttons and the footer */
  }

}

@media screen and (max-width: 500px) { 
.hero-container > h1 {
      font-size: 50px;
      margin-top: 30px;
  }

  .hero-container > p {
      font-size: 30px;
  }

  /* .hero-container_tournament {
    height: 130vh; 
    }  */
  
  .hero-container_tournament > h1 {
      font-size: 50px;
  }

  .hero-container_tournament > p {
      font-size: 30px;
  }
}