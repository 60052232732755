.cards1 {
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  background: #fff;
}

.cards__next1 {
  padding-top: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container1 {
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper1 {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items1 {
  margin-bottom: 24px;
}

.cards__item__horizontal1 {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  height: 240px;
}

.cards__item__link1 {
  display: flex;
  flex-flow: row;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap1 {
  flex-basis: 480px;
  flex-grow: 0;
  position: relative;
  width: 480px;
  min-height: 240px;
  height: auto;
  overflow: hidden;
}

.cards__item__pic-wrap1::after {
  content: attr(data-category);
  position: absolute;
  top: 0;
  right:0;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.fade-img1 {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img1:hover {
  transform: scale(1.1);
}

.cards__item__info1 {
  flex-basis: 608px;
  flex-grow: 1;
  padding: 20px 30px 20px;
  width: auto;
}

.cards__item__base__label1 {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}

.cards__item__base__text1 {
  color: #252e48;
  font-size: 16px;
  line-height: 22px;
  font-weight:normal;
}

.cards__item__base__text__margin1 {
  color: #252e48;
  font-size: 16px;
  line-height: 22px;
  font-weight:normal;
  margin-bottom: 8px;
}

.cards__item__base__text__italics__margin1 {
  color: #252e48;
  font-size: 16px;
  line-height: 22px;
  font-weight:normal;
  font-style: italic;
  margin-bottom: 8px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container1 {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items1 {
    display: flex;
  }
}


 @media only screen and (max-width: 1200px) {
  .cards__item1 {
    margin-bottom: 2rem;
  }
}
  

@media only screen and (max-width: 1024px) {
.cards1 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.cards__next1 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.cards__container1 {
  width: 100%;
  /* padding: 0 0.5rem; */
}
}

@media only screen and (max-width: 700px) {

  .cards__container1 {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  .cards__item__horizontal1 {
    display:flex;
    flex: 1;
    margin-bottom: 1rem;
    border-radius: 10px;
    height: auto;
  }

  .cards__item__link1 {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }

  .cards__item__info1 {
    padding: 20px 30px 20px;
    flex-basis: auto;
  }

  .cards__item__pic-wrap1 {
    flex: 1;
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }

  .cards__item__img1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
}